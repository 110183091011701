#sectionAboutUs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sectionAboutUs .container {
    width: 100%;
}

.aboutUs_content {
    width: 50%;
    margin: 5rem auto 2rem;
    text-align: center;
}

.aboutUs_content h2 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: var(--header-background-dark-blue);
}

.aboutUs_content h3 span {
    color: var(--blue-background);
}

.aboutUs_content p {
    color: var(--clr-black);
    line-height: 34px;
}

.aboutUs_content .title {
    color: var(--light-blue);
    font-size: 26px;
    font-weight: 600;
    padding: 8px 16px;
}

.about_Card_container {
    width: 80%;
    text-align: center;
}

.about_Card_container h2 {
    margin-bottom: 4rem;
}

.about_Card_container h2 span {
    color: var(--blue-background);
}

.investor_about {
    width: 100%;
    height: 100%;
}


.investor_about .avatar-images {
    object-fit: cover;
    box-shadow: var(--light-shadow);
}


.investor_about h4{
    color: var(--header-background-dark-blue);
    font-weight: 600;
}
.investor_about p{
    width: 50%;
    margin: 5px auto;
    color: var(--text);
}

.investor_about .team-Images {
    object-fit: cover;
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
}