/*PEN STYLES*/

.blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, .1);
    margin-bottom: 1.6%;
    background: var(--clr-white);
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
}

.blog-card a {
    color: inherit;
}

.blog-card a:hover {
    color: var(--clr-primary-5);
}

.blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
}

.blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px;
}

.blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
}

.blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left 0.2s;
    background: rgba(0, 0, 0, .6);
    color: var(--clr-white);
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
}

.blog-card .details a {
    text-decoration: dotted underline;
}

.blog-card .details ul li {
    display: inline-block;
}

.blog-card .details .author:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f007";
}

.blog-card .details .date:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f133";
}

.blog-card .details .tags ul:before {
    font-family: FontAwesome;
    content: "\f02b";
    margin-right: 10px;
}

.blog-card .details .tags li {
    margin-right: 2px;
}

.blog-card .details .tags li:first-child {
    margin-left: -4px;
}

.blog-card .description {
    padding: 1rem;
    background: var(--clr-white);
    position: relative;
    z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
    font-family: Poppins, sans-serif;
}

.blog-card .description h1 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
}

.blog-card .description h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: var(--clr-grey-7);
    margin-top: 5px;
}

.blog-card .description .read-more {
    text-align: right;
}

.blog-card .description .read-more a {
    color: var(--clr-primary-3);
    display: inline-block;
    position: relative;
}

.blog-card .description .read-more a:after {
    content: "\f061";
    font-family: FontAwesome;
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    transition: margin 0.3s, opacity 0.3s;
}

.blog-card .description .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
}

.blog-card p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    margin: 1rem 0 0;
}

.blog-card p:first-of-type {
    margin-top: 1.25rem;
}

.blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    background: var(--clr-primary-6);
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
}

.blog-card:hover .details {
    left: 0%;
}

@media (min-width: 640px) {
    .blog-card {
        flex-direction: row;
        max-width: 700px;
    }

    .blog-card .meta {
        flex-basis: 40%;
        height: auto;
    }

    .blog-card .description {
        flex-basis: 60%;
    }

    .blog-card .description:before {
        transform: skewX(-3deg);
        content: "";
        background: var(--clr-white);
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
    }

    .blog-card.alt {
        flex-direction: row-reverse;
    }

    .blog-card.alt .description:before {
        left: inherit;
        right: -10px;
        transform: skew(3deg);
    }

    .blog-card.alt .details {
        padding-left: 25px;
    }
}