#top_navbar {
    background-color: var(--header-background-dark-blue);
    color: var(--background);
    position: sticky;
    top: 0%;
    z-index: 999;
}

.navbar_container {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar_container .navbar_logo {

    color: var(--background);
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-family: serif, sans-serif;
    cursor: pointer;
    text-transform: lowercase;
}

.navbar_logo span {
    color: var(--light-blue);
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 1.2px;
}

.navbar_container .navbar_tabs .tabs {
    display: flex;
    align-items: center;
    list-style: none;
}

.navbar_tabs .tabs .tab {
    margin: 0px 10px;
}

.humbugger_menu {
    display: none;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: var(--background);
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: var(--button-color);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.navbar_container .navbar_tabs {
    display: flex;
    line-height: 0;
}

.navbar_container .navbar_tabs .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    order: 2;
}

.navbar_tabs .nav .user_setting {

    display: flex;
    align-items: center;
    font-size: 28px;
    cursor: pointer;
}

/* Media Query */
@media screen and (max-width: 860px) {

    .navbar_container .navbar_tabs .tabs {
        display: none;
    }

    .humbugger_menu {
        display: inline-block;
        align-items: center;
        font-size: 26px;
        font-weight: 600;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: fixed;
        top: 46px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: start;
    }

    .nav-menu.active {
        background-color: var(--header-background-dark-blue);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        display: table;
    }

    .nav-links:hover {
        background-color: var(--button-color);
        border-radius: 0;
    }
}