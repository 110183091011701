.main_footer {
  background-color: var(--header-background-dark-blue);
  border-radius: 100px 0 0 0;
  margin-top: 5rem;
}

.footer_rowOne {
  padding: 28px 18px 18px 18px;
  color: var(--clr-white);
}

.footer_rowOne h4,
h3 {
  margin: 0;
}

ul {
  padding-left: 48px;
}

h4 {
  padding-left: 48px;
  font-size: 20px;
  color: var(--background);
  font-weight: 800;
}
ul li .link-tab{
  color: var(--light-background);
}

ul li .link-tab:hover{
  color: var(--light-blue);
  border-bottom: 1px dotted var(--link-color-blue);
}
.logo {
  color: var(--light-blue);
  padding-left: 48px;
  font-weight: 800;
}

.footer_containt {
  padding: 5px 48px;
  color: var(--light-background);
}

.address {
  margin-top: 27px;
}

.links {
  margin-top: 27px;
  line-height: 22px;
}

.links li:hover {
  color: var(--clr-primary-6) !important;
}

.footer_rowTwo {
  color: var(--clr-white);
  justify-content: center;
  padding: 8px 0px 16px;
}

.footer_icon {
  margin-top: 20px;
  font-size: 22px;
  align-items: center;
  display: flex;
}

.footer_icon span {
  border: 2px solid var(--clr-white);
  background-color: var(--clr-white);
  color: var(--header-background-dark-blue);
  padding: 5px;
  margin-right: 10px;
  border-radius: var(--radius-round);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.footer_icon span:hover {
  background-color: var(--header-background-dark-blue);
  border: 2px solid var(--light-blue);
  color: var(--clr-white);
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}
.submit_btn {
  font-size: 22px;
  padding-top: 5px;
}

.ant-input-search-button {
  background-color: var(--light-blue) !important;
}

@media screen and (max-width: 768px) {

  .third_col {
    margin-top: 18px;
  }

  .links {
    margin-top: 10px;
  }

  .fourth_col {
    margin-top: 16px;
  }

}

@media screen and (max-width: 425px) {

  .logo {
    margin-bottom: -10px;
    padding: 5px 38px;
  }

  .links {
    padding-left: 38px;
  }

  .footer_containt {
    padding: 5px 38px;
  }

  .address {
    margin-top: 12px;
    padding-left: 38px;
  }

  .ad_phone {
    padding-left: 38px;
  }

  .ad_email {
    padding-left: 38px;
  }

  .Newsletter {
    padding-left: 38px;
  }

  h4 {
    padding-left: 38px;
  }
}