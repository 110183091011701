.home_section {
    width: 100%;
    background: linear-gradient(to left, var(--blue-background), var(--light-blue));
}

.home_container {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_content {
    padding: 56px 16px;
    text-align: center;
}

.home_content h1 {
    color: var(--light-background);
    font-size: 34px;
    margin: 0%;
    letter-spacing: 1.2px;
    word-spacing: 2px;
    font-weight: 600;
    font-family: sans-serif;
}

.home_content h1 span {
    color: var(--background);
    text-transform: capitalize;
}

.home_content h3 {
    color: var(--heading);
    font-size: 24px;
    margin: 12px 0px 0px;
    letter-spacing: 1.2px;
    word-spacing: 2px;
    font-weight: 600;
    font-family: sans-serif;
}

.home_content h3 span {
    margin-left: 6px;
    text-transform: uppercase;
    color: var(--clr-primary-9);
}

.home_content p {
    width: 50%;
    margin: 26px auto 20px;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    line-height: 26px;
    color: var(--background);
    font-weight: bolder;
}


.home_content button {
    box-shadow: var(--dark-shadow);
    background-color: linear-gradient(to right, var(--blue-background), var(--light-blue));
    color: var(--heading);
    border: none;
    border-radius: 10px;
    padding: 16px;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 5px;
}

.home_content button:hover {
    transform: scale(0.9);
}


.home_content .btn {
    box-shadow: var(--dark-shadow);
    background-color: linear-gradient(to right, var(--blue-background), var(--light-blue));
    color: var(--heading);
    border: none;
    border-radius: 10px;
    padding: 16px;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 5px;
}

/* media query Css */
@media screen and (max-width: 860px) {
    .home_section {
        flex-direction: column;
    }

    .home_container {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .home_content h1 {
        font-size: 24px;
    }

    .home_content h3 {
        font-size: 20px;
    }

    .home_content p {
        font-size: 14px;
        padding: 8px;
    }
}

@media screen and (max-width: 360px) {
    .home_content h1 {
        font-size: 22px;
    }

    .home_content h3 {
        font-size: 18px;
    }

    .home_content p {
        font-size: 12px;
        padding: 8px;
    }
}