.userCounter_Section {
    width: 100%;
    background-image: linear-gradient(to bottom right, var(--blue-background), var(--light-blue));
    color: var(--light-background);
    margin: 20px 0px;
}

.user_Counter_Main {
    padding: 16px 8px;
}

.user_learners {
    margin-top: 10px;
    width: 30%;
    text-align: center;
}

.userCounter_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.userCounter_content .user_Heading {
    font-weight: 500;
    font-size: 2.10rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-transform: uppercase;
    letter-spacing: 2.1px;
    text-align: center;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
}

.userCounter_Section .userCount_Container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 16px 8px 24px;
    gap: 8px;
}

.user_learners .user_text_number {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.user_text_number .user_icon {
    font-size: 28px;
    margin-top: 4px;
    font-weight: 600;
}

.user_text_number span {
    font-size: 20px;
    font-weight: 600;
}

.user_learners .user_text {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;

}

/* media query  */
@media screen and (max-width: 860px) {

    .user_learners {
        margin-top: 20px;
        width: 50%;
    }
}

@media screen and (max-width: 560px) {

    .userCounter_content .user_Heading {

        font-size: 1.50rem;
    }

    .user_learners {
        margin-top: 30px;
        width: 100%;
    }
}

.crore_title {
    display: none;
}

.userCount_Container .user_learners:nth-child(2) .crore_title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
    text-transform: uppercase;
}