.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.title span {
    font-size: 0.85em;
    color: var(--button-color);
    margin-right: 1rem;
    margin-top: 0.5rem;
    font-weight: 700;
}

.title span:nth-child(2) {
    font-size: 0.85em;
    margin-left: 1rem;
    margin-top: 0.5rem;
    font-weight: 700;
}

.section-center {
    margin: 0 auto;
    margin-top: 2.5rem;
    width: 100vw;
    height: 500px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
}

.person-img {
    border-radius: 50%;
    margin-bottom: 1rem;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 4px solid var(--clr-grey-8);
    box-shadow: var(--dark-shadow);
}

article h3 {
    text-transform: uppercase;
    color: var(--button-color);
    margin-bottom: 0.25rem;
}

.title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: var(--clr-grey-3);
}

.text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 0.5rem;
    line-height: 1.5;
    color: var(--clr-grey-5);
}

.icon_Div {
    width: 100%;
    text-align: start;
    margin-left: 3rem;
}

.icon {
    font-size: 2rem;
    margin-top: 1rem;
    color: var(--header-background-dark-blue);
    transform: rotateY(180deg);
}

.prev,
.next {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: var(--clr-primary-6);
    box-shadow: var(--box-shadow);
    color: var(--clr-white);
    width: 2.25rem;
    height: 2.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1.5rem;
    border-radius: var(--radius-round);
    cursor: pointer;
    transition: var(--transition);
}

.prev:hover,
.next:hover {
    background: var(--clr-primary-8);
    color: var(--light-blue);
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

@media (min-width: 860px) {
    .text {
        max-width: 45em;
    }

    .prev,
    .next {
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

article.lastSlide {
    transform: translateX(-100%);
}

article.nextSlide {
    transform: translateX(100%);
}



/* Slider About */
.sliderContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slider-left {
    width: 100%;
}

.slider-left img {
    width: 100%;
    color: aqua !important;
}

.sliderContent h3 {
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1.1px;
    margin: 25px auto;
}

.sliderContent>h3 span {
    color: var(--blue-background);
}

.sliderAbout {
    margin-top: 25px;
    display: flex;
    align-items: center;
}

.slider-right {
    margin-left: 5rem;
}

.slider-right .slider_text_content .topContent {
    display: flex;
    align-items: center;
}

.topContent .text {
    width: 200px;
}

.topContent .text h3 {
    color: var(--light-blue);
}

.topContent .text p {
    color: var(--header-background-dark-blue);
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
}

.topContent .dividerHorizontal {
    width: 100%;
}

.topContent .dividerVertical {
    width: 100px;
    transform: rotate(90deg);
}




@media screen and (max-width: 1188px) {
    .topContent .text {
        width: 130px;
    }
}

@media screen and (max-width: 1024px) {

    .slider-left img {
        width: 85%;
        height: 85%;
    }

    .slider-right {
        margin-left: 0rem;
    }

    .topContent .text {
        width: 100px;
    }
}


@media screen and (max-width: 860px) {
    .sliderAbout {
        flex-direction: column;
    }

    .slider-right {
        margin-top: 2rem;
    }

    .topContent .text {
        width: 200px;
    }
}

@media screen and (max-width: 560px) {
    .topContent .text {
        width: 130px;
    }
}


@media screen and (max-width: 375px) {
    .topContent .text {
        width: 90px;
    }
}