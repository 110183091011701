.about-section {
    margin: 20px auto;
}

.about-container {
    margin: 20px auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.aboutLeft,
.aboutRight {
    width: 50%;
    padding: 16px;
}

.aboutRight {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutLeft h4 .title-name {
    text-transform: capitalize;
    color: var(--clr-primary-6);
}

.aboutLeft h2 {

    width: 90%;
    line-height: 1.2;
    text-transform: capitalize;
}

.leftContent {
    margin-left: 6rem;
}

.about-description {
    width: 75%;
    text-align: justify;
}

.about-description p {

    line-height: 1.6;
}

.content_desc {
    margin-left: 20px;
}

.aboutLeft .content .aboutList ul li {
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--text);
    margin-top: 10px;
}

.aboutLeft .content .aboutList ul li span {
    color: var(--clr-grey-5);
    margin-right: 10px;
    text-align: center;
    font-size: 18px;
    margin-top: 5px;
}

.aboutLeft .content .aboutList ul li span:hover {
    transition: 0.5s;
    transform: scale(0.8);
}

.aboutLeft .content .aboutList ul li:hover {

    color: var(--clr-grey-3);
}

.link-btn {
    background-color: var(--blue-background);
    color: var(--clr-white);
    border-radius: var(--radius);
    border: none;
    position: relative;
    height: 60px;
    font-size: 1em;
    padding: 0 2em;
    margin-top: 3rem;
    cursor: pointer;
    transition: 800ms ease all;
    outline: none;
    text-transform: uppercase;
}

.link-btn:hover {
    background-color: var(--light-blue);
    color: var(--clr-white);
}

.link-btn:before,
.link-btn:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background-color: var(--clr-primary-5);
    transition: 400ms ease all;
}

.link-btn:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
}

.link-btn:hover:before,
.link-btn:hover:after {
    width: 100%;
    transition: 800ms ease all;
}

.image-content .img1>img {
    width: 100%;
    height: 100%;
}









/* media query */

@media screen and (max-width: 1024px) {

    .about-container {
        flex-direction: column;
    }

    .aboutLeft,
    .aboutRight {
        padding: 16px;
        width: 90%;
    }

    .aboutLeft h2 {

        width: 80%;
    }

}

@media screen and (max-width: 860px) {

    .aboutLeft .content .aboutList ul li {

        height: 40px;
    }

}

@media screen and (max-width: 560px) {

    .aboutLeft h2 {
        width: 100%;
        font-size: 1.5rem;
    }

    .about-description {
        width: 90%;
    }

    .link-btn {
        font-size: 1.2rem;
    }

}

@media screen and (max-width: 375px) {

    .link-btn {
        font-size: 1rem;
    }

}