#service-section {
  background-color: var(--background);
  padding-bottom: 2rem;
}

.service_main {
  width: 100%;
  height: 400px;
  background: linear-gradient(to left, var(--blue-background), var(--light-blue));
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service_header_btn button {
  box-shadow: var(--box-shadow);
  background: linear-gradient(to right, #6190E8, #A7BFE8);
  color: var(--header-background-dark-blue);
  border: none;
  border-radius: 10px;
  padding: 16px;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
  margin: 5px;
  z-index: 55;
}

.service_header_btn {
  margin-top: 12px;
}

.service_header_btn button:nth-child(2) {
  background: linear-gradient(to left, #6190E8, #A7BFE8);
}

.main_heading h2 {
  color: var(--clr-white);
  margin-top: 0;
}

.main_heading h4 {
  color: var(--clr-white);
  text-align: center;
  margin-top: 12px;
}

.main_heading h2 span {
  color: var(--header-background-dark-blue);
}

.main_heading h4 span {
  color: var(--header-background-dark-blue);
}

.main_row {
  gap: 16px;
}

.card {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
}



.card_image {
  border-radius: 15px;
  -webkit-transition: all .9s ease;
  -moz-transition: all .9s ease;
  -o-transition: all .9s ease;
  -ms-transition: all .9s ease;
  width: 100%;
  height: 200px;
}


.card .text-container {
  height: 250px;
}

.ant-card-cover {
  overflow: hidden;
}

.card_image:hover {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  transition: all 0.5s;
  opacity: 0.7;
}

.main_row {
  justify-content: space-evenly;
}

.card_container {
  margin-top: 20px;
  height: 500px;
}

.card_container:hover {
  transform: scale(1.03);
  transition: all 0.3s linear;
  border-radius: 15px;
}

.card .des_course {
  font-size: 14px;
  text-align: justify;
}


@media screen and (max-width: 1440px) {
  .card .des_course {
    font-size: 12px;
  }

  .card .text-container {
    height: 200px;
  }
}


@media screen and (max-width: 1024px) {
  .card .des_course {
    font-size: 16px;
  }

  .card_image {
    width: 100%;
    height: 350px;
  }

  .card .text-container {
    height: 200px;
  }

  .card_container {
    margin-top: 20px;
    height: 600px;
  }
}

@media screen and (max-width: 860px) {
  .card .des_course {
    font-size: 16px;
  }

  .card_image {
    width: 100%;
    height: 280px;
  }

  .card .text-container {
    height: 250px;
  }
}

@media screen and (max-width: 576px) {
  .card .des_course {
    font-size: 16px;
  }

  .card_image {
    width: 100%;
    height: 300px;
  }

  .card .text-container {
    height: 250px;
  }
}

@media screen and (max-width: 425px) {
  .service_main {
    padding-top: 0;
  }

  .card_image {
    width: 100%;
    height: 250px;
  }

  .card .des_course {
    font-size: 14px;
  }

  .card .text-container {
    height: 270px;
  }
}

@media screen and (max-width: 320px) {
  .card_image {
    width: 100%;
    height: 200px;
  }

  .card .text-container {
    height: 340px;
  }
}